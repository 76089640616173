import React from "react"
import Header from "./header"
import HomePage from "./home"
import Footer from "./footer"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  // let header

  // if (isRootPath) {
  //   header = (
  //     <h1 className="main-heading">
  //       <Link to="/">{title}</Link>
  //     </h1>
  //   )
  // } else {
  //   header = (
  //     <Link className="header-link-home" to="/">
  //       {title}
  //     </Link>
  //   )
  // }

  return (
    <div className="global-wrapper">
      <Header siteTitle={title} />
      {isRootPath && <HomePage />}
      <main className="content-wrapper">{children}</main>
      <Footer />
    </div>
  )
}

export default Layout

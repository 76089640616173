import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `#2B2D42`,
    }}
  >
    <nav
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        display: `flex`,
        flexDirection: `row`,
        alignItems: `center`,
        justifyContent: `space-between`,
        paddingLeft: `1px`,
        paddingRight: `1px`,
      }}
    >
      <Link
        to="/"
        style={{
          fontSize: 25,
        }}
        className="nav-home"
      >
        {siteTitle}
      </Link>
      <div
        style={{
          display: `flex`,
          flexDirection: `row`,
        }}
      >
        <Link to="/blog" className="nav-items" style={{ fontSize: 20 }}>
          Blog
        </Link>
        <Link to="/projects" className="nav-items" style={{ fontSize: 20 }}>
          Projects
        </Link>
      </div>
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

import React from "react"
import resume from "../images/resume.png"
import github from "../images/icons8-github.svg"
import linkedin from "../images/icons8-linkedin-circled.svg"

const Footer = () => (
  <footer>
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        display: `flex`,
        justifyContent: `center`,
        alignItems: "center",
      }}
    >
      <a href={"/resume.pdf"} className="footer-spacing">
        <img src={resume} className="footer-icons" alt="resume" />
      </a>
      <a
        href={"https://www.linkedin.com/in/jackiebanh/"}
        className="footer-spacing"
      >
        <img src={linkedin} alt="linkedin logo" />
      </a>
      <a href={"https://github.com/jackiebanh"} className="footer-spacing">
        <img src={github} alt="github logo" />
      </a>
    </div>
  </footer>
)

export default Footer

import React from "react"
import Typist from "react-typist"

const HomePage = () => (
  <div className="home-welcome">
    <Typist startDelay={1000}>
      <span className="animated-text">Hello, I'm Jackie</span>
    </Typist>
  </div>
)

export default HomePage
